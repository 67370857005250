import * as React from 'react';
import { Outlet } from 'react-router';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';

import app from 'src/app';

import { ClassName } from 'src/lib';

export interface ILayoutMainPropTypes {
	header?: React.ReactNode;
	footer?: React.ReactNode;
}

const cn = ClassName.create('layout-main', require('./style.less'));

export function LayoutMainComponent(props: ILayoutMainPropTypes): React.ReactNode {
	React.useLayoutEffect(() => {
		return reaction(
			() =>
				[
					app.models.leaderboard.currentLeaderboardId.organizationId,
					app.models.leaderboard.currentLeaderboardId.resourceId,
				].join('__'),
			(currLeaderboardId, prevLeaderboardId) => {
				window.document.documentElement.classList.remove(`b-event__${prevLeaderboardId}`);
				window.document.documentElement.classList.add(`b-event__${currLeaderboardId}`);
			},
			{ fireImmediately: true }
		);
	});

	return (
		<div className={cn.get()}>
			{!!props.header && <header className={cn.get('header')}>{props.header}</header>}
			<main className={cn.get('content')}>
				<Outlet />
			</main>
			{!!props.footer && <footer className={cn.get('footer')}>{props.footer}</footer>}
		</div>
	);
}

export const LayoutMain = observer(LayoutMainComponent);
