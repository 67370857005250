import * as React from 'react';

import { ClassName } from 'src/lib';

import { TUserSocialNetwork } from 'app';

import { Throbber } from 'src/components/throbber';

import { Button } from '..';

export type TButtonPropTypes = {
	provider: string | TUserSocialNetwork;
	type?: 'link' | 'script';
	link?: string;
	script?: string;
	scriptData?: Record<string, string>;
	isConnected?: boolean;
	onClick?: (event: React.MouseEvent) => void;
} & React.PropsWithChildren;

const cn = ClassName.create('button-social-auth', require('./style.less'));

const ICONS = {
	twitch: null,
	steam: null,
	google: null,
	telegram: null,
	winline: null,
	paripass: null,
};

const SCRIPT_DATA: Record<TUserSocialNetwork, Record<string, string>> = {
	twitch: {},
	google: {},
	steam: {},
	telegram: {
		size: 'large',
		userpic: 'false',
		radius: '0',
	},
	winline: {},
	paripass: {},
};

export function ButtonSocialAuth(props: TButtonPropTypes): React.ReactElement<TButtonPropTypes> | null {
	const ref = React.useRef<HTMLDivElement>(null);
	const [isLoading, setIsLoading] = React.useState(props.type === 'script');

	const allowedProviders = Object.keys(ICONS);

	React.useLayoutEffect(() => {
		if (!ref.current || props.type !== 'script' || !props.script) {
			return;
		}

		const script = document.createElement('script');

		Object.entries({
			...(SCRIPT_DATA[props.provider as TUserSocialNetwork] || {}),
			...(props.scriptData || {}),
		}).forEach(([attribute, value]) => {
			script.setAttribute(['data', attribute].join('-'), value);
		});

		script.src = props.script;

		script.addEventListener('load', () => {
			setIsLoading(false);
		});

		ref.current?.appendChild(script);

		return () => {
			ref.current?.removeChild(script);
		};
	}, []);

	if (!allowedProviders.includes(props.provider)) {
		return null;
	}

	return (
		<div ref={ref} className={cn.get([isLoading && 'loading'])}>
			{isLoading ? (
				<Throbber size="small" />
			) : (
				<Button
					Element="button"
					onClick={(event) => {
						event.preventDefault();

						if (typeof props.onClick === 'function') {
							props.onClick(event);
						}

						if (props.type === 'link' && props.link) {
							window.open(props.link, '_self', 'noopener, noreferrer');
						}
					}}
				>
					{props.children || props.provider}
				</Button>
			)}
		</div>
	);
}
